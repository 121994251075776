.Introduce {
    padding-top: 50px;
    display: flex;
    align-items: space-around;
    width: 100%;
    height: 100%;
    flex-wrap: wrap;
    justify-content: center;
    color: var(--third-color);
    min-width: 800px;

    .Introduce-title {
        width: 100%;
        text-align: center;
        font-size: 40px;
        font-weight: 700;
    }

    .Introduce-main {
        width: 80%;
        display: flex;
        justify-content: space-around;
        height: 80%;

        >li {
            width: 30%;
            height: 400px;
            margin: 10px;

            .Introduce-main-top {
                display: flex;
                flex-wrap: wrap;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                font-size: 24px;

                .Introduce-main-topIcon {
                    margin-bottom: 5px;
                    font-size: 20px;
                }
            }

            ul {
                .addText {
                    transition: all .3s;
                }

                .addText:hover {
                    cursor: pointer;
                    background: var(--secondary-color1);
                }

                li {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 5px;
                    padding: 10px;
                    width: 100%;
                    min-height: 80px;
                    background: var(--primary-color1);
                    margin: 20px 0;
                    font-size: 16px;
                    text-align: center;
                }
            }
        }
    }
}