* {
    padding: 0;
    box-sizing: border-box;
}

body {
    background-color: rgb(242, 242, 242);
    overflow-x: hidden;
}

em,
i {
    font-style: normal
}

li {
    list-style: none
}

/* 照顾低版本,连接边框问题 */
img {
    border: 0;
    /* 取消图片地测空白 */
    vertical-align: middle
}

button {
    cursor: pointer
}

a {
    text-decoration: none
}


button,
input,
textarea {

    border: 0px;
    outline: none;
}

body {
    -webkit-font-smoothing: antialiased;
    background-color: #fff;

}

.hide,
.none {
    display: none
}

/* 清除浮动 */
.clearfix:after {
    visibility: hidden;
    clear: both;
    display: block;
    content: ".";
    height: 0
}

.clearfix {
    *zoom: 1
}