.coming-soon {
    width: 60vw;
    height: 100vh;
    padding-top: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    font-family: Arial, sans-serif;
    color: #fff;
    background-color: #222;

    h1 {
        font-size: 50px;
        margin: 0px;
    }

    p {
        font-size: 24px;
        text-align: center;
        margin-bottom: 30px;
    }

    .countdown {
        display: flex;
        justify-content: center;
        margin-bottom: 50px;
    }

    .countdown-item {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-right: 40px;
    }

    .countdown-value {
        font-size: 48px;
    }

    .countdown-label {
        font-size: 24px;
        text-transform: uppercase;
        letter-spacing: 2px;
        margin-top: 10px;
    }

    .signup-form {
        margin-top: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    input[type="email"] {
        padding: 10px;
        border-radius: 5px;
        border: none;
        width: 300px;
        margin-right: 10px;
    }

    button {
        background-color: #ffcc00;
        color: #222;
        border: none;
        padding: 10px 20px;
        border-radius: 5px;
        font-size: 18px;
        font-weight: 600;
        cursor: pointer;
    }

    button:hover {
        background-color: #f2b807;
    }
}

.right {
    width: 40vw;
    height: 100vh;
}